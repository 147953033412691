@keyframes extremeShake {
    0% { transform: translateX(0); background-color: #FFA800; }
    10% { transform: translateX(-15px); background-color: #FFA800; }
    20% { transform: translateX(15px); background-color: #FFA800; }
    30% { transform: translateX(-15px); background-color: #FFA800; }
    40% { transform: translateX(15px); background-color: #FFA800; }
    50% { transform: translateX(-15px); background-color: #FFA800; }
    60% { transform: translateX(15px); background-color: #FFA800; }
    70% { transform: translateX(-15px); background-color: #FFA800; }
    80% { transform: translateX(15px); background-color: #FFA800; }
    90% { transform: translateX(-15px); background-color: #FFA800; }
    100% { transform: translateX(0); background-color: #FFA800; }
  }
  
  .shake {
    animation: extremeShake 0.5s ease-in-out; /* Shorter duration for rapid shaking */
  }
  
body {
  background: #0A1111;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: Jura !important;
}

body p {
  font-family: Jura !important;
}


@font-face {
  font-family: Jura;
  src: url("./assets/font/Jura.ttf");
}

@font-face {
  font-family: JosefinSans;
  src: url("./assets/font/JosefinSans.ttf");
}

@font-face {
  font-family: Lato;
  src: url("./assets/font/Lato-Regular.ttf");
}


.App {
  font-family: Jura !important;
}

.Stepper svg, 
.Stepper text {
  color: white !important;
  fill: white !important;
  font-family: Jura !important;
}

.MuiStepConnector-root {
  display: none !important;
  font-family: Jura !important;
}

.SwapPage .am5-layer-30 {
  display: none !important;
  font-family: Jura !important;
}

canvas.am5-layer-30 {
  display: none;
}

#chartcontrols {
  height: auto;
  padding: 5px 5px 0 16px;
  max-width: 100%;
  color: white !important;
  background-color: #375757 !important;
}